import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { FilterListContainer, FilterListHeading, FilterListGroup, FilterList, Badge } from '@class101/ui';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "filterlist"
    }}>{`FilterList`}</h1>
    <p>{`하단 컨텐츠를 필터링하는 메뉴로 사용되는 컴포넌트입니다.`}</p>
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={0} __code={'<FilterListContainer>\n  <FilterListGroup>\n    <FilterListHeading>발송 관리</FilterListHeading>\n    <FilterList active>\n      결제 완료\n      <Badge size=\"sm\" pill>\n        12\n      </Badge>\n    </FilterList>\n    <FilterList>\n      상품 준비중\n      <Badge size=\"sm\" pill>\n        9\n      </Badge>\n    </FilterList>\n    <FilterList>배송 중</FilterList>\n    <FilterList>배송 완료</FilterList>\n  </FilterListGroup>\n  <FilterListGroup>\n    <FilterListHeading>반품/취소 관리</FilterListHeading>\n    <FilterList>반품 요청됨</FilterList>\n    <FilterList>반품 완료</FilterList>\n    <FilterList />\n    <FilterList />\n  </FilterListGroup>\n</FilterListContainer>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      FilterListContainer,
      FilterListHeading,
      FilterListGroup,
      FilterList,
      Badge,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <FilterListContainer mdxType="FilterListContainer">
    <FilterListGroup mdxType="FilterListGroup">
      <FilterListHeading mdxType="FilterListHeading">발송 관리</FilterListHeading>
      <FilterList active mdxType="FilterList">
        결제 완료
        <Badge size="sm" pill mdxType="Badge">
          12
        </Badge>
      </FilterList>
      <FilterList mdxType="FilterList">
        상품 준비중
        <Badge size="sm" pill mdxType="Badge">
          9
        </Badge>
      </FilterList>
      <FilterList mdxType="FilterList">배송 중</FilterList>
      <FilterList mdxType="FilterList">배송 완료</FilterList>
    </FilterListGroup>
    <FilterListGroup mdxType="FilterListGroup">
      <FilterListHeading mdxType="FilterListHeading">반품/취소 관리</FilterListHeading>
      <FilterList mdxType="FilterList">반품 요청됨</FilterList>
      <FilterList mdxType="FilterList">반품 완료</FilterList>
      <FilterList mdxType="FilterList" />
      <FilterList mdxType="FilterList" />
    </FilterListGroup>
  </FilterListContainer>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      